<template>
    <!-- contact us -->
    <section class="faq-contact">
        <div class="row mt-5 pt-75">
            <div class="col-12 text-center">
                <h2>You still have a question?</h2>
                <p class="mb-3">
                    If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!
                </p>
            </div>
            <div class="col-sm-6">
                <div class="card text-center faq-contact-card shadow-none py-1">
                    <div class="accordion-body">
                        <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                            <i class="bi bi-telephone-outbound text-primary"  ></i>
                        </div>
                        <h4>+ (810) 2548 2568</h4>
                        <span class="text-body">We are always happy to help!</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card text-center faq-contact-card shadow-none py-1">
                    <div class="accordion-body">
                        <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                            <i class="bi bi-envelope-open text-primary"  ></i>
                        </div>
                        <h4>hello@help.com</h4>
                        <span class="text-body">Best way to get answer faster!</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--/ contact us -->
</template>
