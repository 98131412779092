<template>
    <!-- form -->
    <form class="validate-form">
    <!-- filter questions -->
    <base-card-plain :showHeader="false">
        <template #default>


                <div class="row">
                    <div class="text-center pb-3">
                        <h2 class="text-primary">
                            <i class="bi bi-folder-check" ></i>
                            How can we support you?
                        </h2>
                        <p class="card-text mb-2">
                         Raise a support ticket, provide feedback or request a feature.
                        </p>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="basicSelect">What do you intend to do? </label>
                            <select class="form-select" id="basicSelect">
                                <option>Select</option>
                                <option>Raise a support ticket</option>
                                <option>Provide feedback</option>
                                <option>Request a feature</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="account-birth-date">What's the subject of your request?</label>
                            <input type="text" class="form-control " placeholder="Subject" id="account-birth-date" name="dob" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="accountTextarea">Request details.</label>
                            <textarea class="form-control" id="accountTextarea" rows="4" placeholder="Enter request details here here..."></textarea>
                        </div>
                    </div>

                </div>


                <div class="col-12">
                    <base-button btnColor="primary" >Save information</base-button>
                </div>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
// import components
import BaseCardPlain from '@/components/ui/BaseCardPlain.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    },
    components:{
        BaseCardPlain,
        BaseButton
    }
}


</script>
